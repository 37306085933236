import React from 'react'
import { Link } from 'gatsby'

import RightChevron from '../SVG/RightChevron'
import LeftChevron from '../SVG/LeftChevron'

const Pagination = ({ next, previous }) => {
  return (
    <div>
      {next ? (
        <div className="lg:flex items-center">
          <div className="lg:w-1/2">
            <div className="heading-xl pb-12">Next Case Study</div>

            <Link
              className="block lg:inline-block button button--blue border border-white font-semibold"
              to={`/case-studies/${next.uid}`}
              rel="next"
            >
              <div className="button__inner">
                {next.data.heading}
                <div className="ml-8">
                  <RightChevron />
                </div>
              </div>
            </Link>
          </div>
          <div className="hidden lg:block lg:w-1/2">
            <div className="img-break-out">
              <img src={next.data.hero.url} alt={next.data.hero.alt} />
            </div>
          </div>
        </div>
      ) : (
        <div className="lg:flex items-center">
          <div className="lg:w-1/2">
            <div className="heading-xl pb-12">Previous Case Study</div>

            <Link
              className="block lg:inline-block button button--left border border-white font-semibold"
              to={`/case-studies/${previous.uid}`}
              rel="prev"
            >
              <div className="button__inner">
                <div className="mr-8">
                  <LeftChevron />
                </div>
                {previous.data.heading}
              </div>
            </Link>
          </div>
          <div className="hidden lg:block lg:w-1/2">
            <div className="img-break-out">
              <img src={previous.data.hero.url} alt={previous.data.hero.alt} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Pagination
