import React from 'react'
import Img from 'gatsby-image'

const Intro = ({ post, localImage, heroCrop }) => {
  return (
    <div className="bg-blue text-white">
      <div className="wrapper xl:flex">
        <div className="xl:w-5/12">
          <div className="pt-4 lg:pt-20 xl:pb-20 xl:w-3/4 lg:-ml-8 lg:flex xl:flex-col items-end">
            <div className="lg:w-7/12 xl:w-full lg:pl-8">
              <h4 className="mb-4 font-semibold">Case Study</h4>
              {post.heading && (
                <h1 className="mb-12 lg:mb-24 xl:mb-12 heading-2xl">
                  {post.heading}
                </h1>
              )}
            </div>
            <div className="md:flex xl:block lg:w-1/3 xl:w-full lg:pl-8 md:-ml-4 xl:ml-0">
              {post.work_completed && (
                <div className="border-t border-white pt-6 mb-8 md:w-1/2 md:ml-4 xl:ml-0 xl:w-3/5">
                  <h4 className="mb-4 font-bold">Work Completed</h4>
                  <div
                    className="font-medium"
                    dangerouslySetInnerHTML={{
                      __html: post.work_completed.html,
                    }}
                  />
                </div>
              )}
              {post.technologies && (
                <div className="border-t border-white pt-6 mb-8 md:w-1/2 md:ml-4 xl:ml-0 xl:w-3/5">
                  <h4 className="mb-4 font-bold">Technologies</h4>
                  <div
                    className="font-medium"
                    dangerouslySetInnerHTML={{
                      __html: post.technologies.html,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="xl:w-7/12">
          {localImage && (
            <div className="break-out-left break-out-right xl:img-break-out lg:mx-0 h-full">
              <Img
                className="hidden lg:block xl:hidden h-full"
                fluid={heroCrop.hero.localFile.childImageSharp.fluid}
                alt={heroCrop.alt}
              />
              <Img
                className="lg:hidden xl:block h-full"
                fluid={localImage.fluid}
                alt={localImage.alt}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Intro
