import React from 'react'

import GallerySlider from '../../components/GallerySlider'

const Main = ({ post }) => {
  return (
    <div className="bg-white lg:bg-blue">
      <div className="relative pt-12 bg-white bg-after-white bg-after-white--left wrapper sm:pb-12 lg:pb-20 lg:pt-24">
        <div className={`lg:flex lg:-ml-12 ${post.gallery.length !== 0 ? 'lg:mb-38' : ''}`}>
          <div
            className="mb-8 text-2xl font-semibold leading-tight rte lg:w-1/3 lg:pl-12"
            dangerouslySetInnerHTML={{ __html: post.lead.html }}
          />
          <div className="hidden w-1/12 lg:block" />
          <div
            className="mb-12 font-semibold lg:mb-0 rte lg:w-1/2 lg:pl-12"
            dangerouslySetInnerHTML={{ __html: post.body.html }}
          />
        </div>
        {post.gallery.length !== 0 && (
          <GallerySlider className="case-study-slider" data={post.gallery} />
        )
        }
      </div>
    </div>
  )
}

export default Main
